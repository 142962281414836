<script setup lang="ts">
  import { HeartIcon } from '@heroicons/vue/24/outline'
  import AuthenticationService from '~/services/AuthenticationService'
  import FavoriteService from '~/services/FavoriteService'
  import { useModalStore } from '~/stores/modalStore'
  import GenericSuccessModal from '~/components/modals/GenericSuccessModal.vue'
  import GenericErrorModal from '~/components/modals/GenericErrorModal.vue'

  const props = defineProps({
    spaceId: {
      type: Number,
      required: true,
    },
  })

  const { spaceId } = toRefs(props)
  const favoriteSuccessMessage = 'Espaço adicionado aos favoritos com sucesso.'

  const handleAddSpaceToUserFavorites = async () => {
    if (AuthenticationService.isAuthenticated()) {
      try {
        await FavoriteService.addFavorite(spaceId.value)
        useModalStore().showSuccessModal = true
        useModalStore().successMessage = favoriteSuccessMessage
      } catch (error: any) {
        if (error.message) {
          useModalStore().showErrorModal = true
          useModalStore().errorMessage = error.message ?? ''
        }
      }
    } else {
      useModalStore().showUserNotLoggedModal = true
    }
  }
</script>

<template>
  <div class="absolute right-0 top-0 flex">
    <GenericSuccessModal />
    <GenericErrorModal />
    <LazyModalsUserNotLoggedModal />
    <div class="mx-1 mt-1 inline-flex">
      <span
        class="hint--left hint--no-arrow hint--rounded z-10 text-xs text-gray-100"
        aria-label="Adicionar aos favoritos">
        <HeartIcon
          class="h-8 w-8 cursor-pointer hover:text-red-600"
          title="Adicionar aos favoritos"
          @click.prevent="handleAddSpaceToUserFavorites" />
      </span>
    </div>
  </div>
</template>
